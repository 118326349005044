import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

import { DATA } from "../components/data"

const VisitPage = () => {
  const { contact } = DATA

  return (
    <Layout>
      <SEO title={`Zapisy`} />

      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="contact-text">
                <h1 className="contact-text-header">Zapisy / Infolinia:</h1>
                <p>
                  <a href={`tel:${contact.phone_simple}`}>
                    Medycyna estetyczna <strong>{contact.phone}</strong>
                  </a>
                </p>
                <p>
                  <a href={`tel:${contact.phone_podologia_simple}`}>
                    Podologia <strong>{contact.phone_podologia}</strong>
                  </a>
                </p>
                <p>
                  <a href={`mailto:${contact.email}`} target="_top">
                    {contact.email}
                  </a>
                </p>
                <p>Numer konta: 56 1090 2590 0000 0001 4669 0634</p>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="contact-text">
                <h2 className="contact-text-header">Adres:</h2>
                <p>{contact.address}</p>
                <p>{contact.city}</p>
                <p>{contact.week_hours}</p>
                <p>{contact.weekend_hours}</p>

                <a
                  href="https://www.google.com/maps/place/Balicka+Clinic/@50.0229982,22.6631366,15z/data=!4m5!3m4!1s0x0:0x9e8308a27a655c89!8m2!3d50.0229982!4d22.6631366"
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                >
                  <button className="contact-button">
                    POKAŻ W GOOGLE MAPS
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VisitPage
